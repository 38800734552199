<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  hideIfEmpty,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { useRadiusItemMenu } from './radiusItemMenu';
import { useCoordinatesItemMenu } from './coordinatesItemMenu';
import { useCopy } from '@/compositions/copy';
import { formatCoordinates } from '@/utils';
import { useGeotagItemMenu } from '@/modules/geotags/compositions/geotagItemMenu';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { useDefaultItemMenu } from '@/modules/geotags/ui/geotag-card/general/defaultItemMenu';
import {genAccessListFragment} from "@/modules/access/compositions/access-list-fragment";

export default {
  name: 'LandmarkCardGeneral',
  setup() {
    const entity = inject('entity');
    const { copyWithAlert } = useCopy();

    const { genMenu: genGeotagMenu } = useGeotagItemMenu();
    const { genMenu: genRadiusItemMenu } = useRadiusItemMenu();
    const { genMenu: genCoordinatesItemMenu } = useCoordinatesItemMenu();
    const { genMenu: genAltitudeMenu } = useDefaultItemMenu();

    const { getGeotagById } = useGeotags();

    const items = computed(() => [
      genSubheaderItem('Position'),
      genDefaultItem({
        title: 'Center',
        subTitle: formatCoordinates(entity.value.positionCenter.value),
        icon: '$marker_set',
        invert: true,
        actions: genCoordinatesItemMenu(entity.value.positionCenter)
      }),
      genDefaultItem({
        title: 'Radius (m)',
        subTitle: entity.value.positionRadius.value,
        icon: '$radius',
        invert: true,
        actions: genRadiusItemMenu(entity.value.positionRadius)
      }),
      genDefaultItem({
        title: 'Geotag',
        subTitle: getGeotagById(entity.value.positionGeotagId.value)?.name,
        icon: '$geotag',
        invert: true,
        actions: genGeotagMenu(entity.value.positionGeotagId, entity.value.id)
      }),
      genDefaultItem({
        title: 'Min altitude',
        subTitle: entity.value?.positionMinAltitude?.value,
        icon: '$min_altitude',
        invert: true,
        actions: genAltitudeMenu(entity.value?.positionMinAltitude)
      }),
      genDefaultItem({
        title: 'Max altitude',
        subTitle: entity.value?.positionMaxAltitude?.value,
        icon: '$max_altitude',
        invert: true,
        actions: genAltitudeMenu(entity.value?.positionMaxAltitude)
      }),
      ...hideIfEmpty(entity.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: entity.value.description
        })
      ]),
      ...genAccessListFragment({
        readerGroup: entity.value.userGroupByReadergroup.groupName,
        userGroup: entity.value.userGroupByUsergroup.groupName,
        editorGroup: entity.value.userGroupByEditorgroup.groupName
      }),
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          icon: '$uuid',
          title: entity.value.id
        },
        {
          click: () => copyWithAlert(entity.value.id)
        }
      ),
      genServiceNameItem(entity.value.name, {
        click: () => copyWithAlert(entity.value.name)
      })
    ]);

    return { items };
  }
};
</script>

<style></style>
